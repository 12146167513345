<template>
  <b-row>
    <b-col md="4" sm="12">
      <Card class="report mb-3" no-shadow>
        <template v-slot:content>
          <h3 class=" mb-4">{{ $t('PROFILE.objective_report') }}</h3>
          <b-img src="/media/img/report_3.png" class="mb-4" center/>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Debitis laboriosam id dolorum consectetur saepe illo sequi ad! Deleniti ad assumenda tenetur voluptates voluptas natus dolorum, amet nihil molestias dolores tempora.</p>
          <b-button variant="primary" block @click="downloadObjectivesReport">Generar reporte</b-button>
        </template>
      </Card>
    </b-col>

    <b-col md="4" sm="12">
      <Card class="report mb-3" no-shadow>
        <template v-slot:content>
          <h3 class=" mb-4">{{ $t('PROFILE.lab_report') }}</h3>
          <b-img src="/media/img/report_1.png" class="mb-4" center/>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Debitis laboriosam id dolorum consectetur saepe illo sequi ad! Deleniti ad assumenda tenetur voluptates voluptas natus dolorum, amet nihil molestias dolores tempora.</p>
          <b-button variant="primary" block @click="downloadLabsReport">Generar reporte</b-button>
        </template>
      </Card>
    </b-col>

    <b-col md="4" sm="12">
      <Card class="report mb-3" no-shadow>
        <template v-slot:content>
          <h3 class=" mb-4">{{ $t('PROFILE.career_report') }}</h3>
          <b-img src="/media/img/report_2.png" class="mb-4" center/>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Debitis laboriosam id dolorum consectetur saepe illo sequi ad! Deleniti ad assumenda tenetur voluptates voluptas natus dolorum, amet nihil molestias dolores tempora.</p>
          <b-button variant="primary" block @click="downloadCareerReport">Generar reporte</b-button>
        </template>
      </Card>
    </b-col>

    <loading :loading="isLoading" />
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProfileReports',
  components: { },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions({
      getObjectivesReport: 'user/getObjectivesReport',
      getLabsReport: 'user/getLabsReport',
      getCareerReport: 'user/getCareerReport'
    }),
    downloadObjectivesReport () {
      this.getObjectivesReport()
        .then(data => window.open(this.objectivesReportUrl))
    },
    downloadCareerReport () {
      this.getCareerReport()
        .then(data => window.open(this.careerReportUrl))
    },
    downloadLabsReport () {
      this.getLabsReport()
        .then(data => window.open(this.labsReportUrl))
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/currentUser',
      isLoading: 'user/isLoading'
    })
  }
}
</script>

<style lang="scss" scoped>
.card.report {
  img {
    max-width: 100%;
  }
}

.nav-tabs {
  border-color: var(--primary-dark);

  .nav-item > * {
    color: var(--primary-dark);
  }

  .nav-link:hover {
    border-color: var(--primary-dark) var(--primary-dark) transparent var(--primary-dark);
  }

  .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-color: var(--primary-dark) var(--primary-dark) transparent var(--primary-dark);
    background-color: var(--primary-dark);
    color: var(--primary-inverse);
  }
}

.profile-menu {
  margin-top: 32px;
  padding: 8px ;

  & > * {
    padding: 8px 12px;
    border-radius: 8px;
    cursor: pointer;

    &.active,  &.active:hover {
      color: var(--primary-inverse);
      background-color: var(--primary);
    }

    &:hover {
      color: var(--primary-inverse);
      background: var(--primary);
    }
  }
}

</style>
